import { Injectable } from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { CookieService } from 'ngx-cookie-service';
import { environment } from 'src/environments/environment';
import { Permission } from '../CallServices/Permission';
import { CallserviceService } from '../CallServices/callservice.service';
import { ToastrService } from 'ngx-toastr';
import { TokenEncryptionService } from '../CallServices/token-encryption.service';
import { FirebaseService } from '../CallServices/firebase.service';
import { AngularFireAuth } from '@angular/fire/compat/auth';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardUserGuard implements CanActivate {
  foundMatch: boolean;
  trimmedKey: string;
  maintenanceMode: any;
  username: string;

  constructor(public afAuth: AngularFireAuth, private FirebaseService: FirebaseService, private cookieService: CookieService, private Route: Router, private route: ActivatedRoute,
    public permission: Permission, private api: CallserviceService, private toastr: ToastrService,
    private tokenEncryptDecrypt: TokenEncryptionService) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    this.tokenEncryptDecrypt.getkey(sessionStorage.getItem("KEY"))
    if (this.cookieService.check('UID') && this.cookieService.check('AID')) { //if (localStorage.getItem('newmyqone')) 

      this.FirebaseService.getMaintenanceMode().subscribe(data => {
        this.maintenanceMode = data.maintenanceMode;
        if (this.maintenanceMode == true) {
          if (this.tokenEncryptDecrypt.decryptToken(sessionStorage.getItem("maintenance")) != "true") {
            this.Route.navigate(['/financialmetrics/maintenance']);
            return false;
          }
        }
        else {
          return true;
        }
      });

      if (sessionStorage.getItem("QPM")) {
        return true;
      } else {
        let Permissions = this.tokenEncryptDecrypt.decryptToken(localStorage.getItem("Permissions"));
        //console.log('Print Permissions in localstorage',Permissions)
        let GroupWithMappedPractice = this.tokenEncryptDecrypt.decryptToken(sessionStorage.getItem("GroupWithMappedPractice"))  //Decrytption let GroupWithMappedPractice= sessionStorage.getItem("GroupWithMappedPractice") 
        //console.log('Print GroupWithMappedPractice in sessionstorage',GroupWithMappedPractice)
        if (GroupWithMappedPractice && Permissions) {
          this.foundMatch = false;
          this.foundMatch = this.checkPermisssion(route, state);

          if (this.foundMatch) {
            return true;
          } else {
            if (this.trimmedKey == undefined || this.trimmedKey == null || this.trimmedKey == '') {  //if New components created and permission not set
              //let URL = localStorage.getItem('currentURL');  //last visited Dashboard  
              //URL =this.tokenEncryptDecrypt.decryptToken(URL);   //Decryption
              let URL = this.tokenEncryptDecrypt.decryptToken(localStorage.getItem("currentURL"))   //Decryption
              //console.log('currentURL',URL)
              this.Route.navigate(["/qdashboards/AppAccess"])
            }
            else { //need to check this eld
              // this.toastr.warning("You dont have permissions");
              let URL = this.tokenEncryptDecrypt.decryptToken(localStorage.getItem("currentURL"))  // Decryption
              //console.log('currentURL',URL)
              this.Route.navigate(["/qdashboards/AppAccess"])
            }
          }
        }
        else {
          if (this.tokenEncryptDecrypt.decryptToken(sessionStorage.getItem("isMIUuser")) == "true") {
            this.foundMatch = this.checkPermisssion(route, state);
            return this.foundMatch;
          } else {
            window.location.assign(
              environment.gotomyqone
            );
            return false;
          }

        }
      }

    }
    else {
      // environment.Login_url
      window.location.assign(
        environment.Login_url
      );
      return false;
    }

  }
  checkPermisssion(route, state) {
    try {
      //Decryption
      let localstorage = localStorage.getItem("Permissions");
      const Pathpermissions = this.tokenEncryptDecrypt.decryptToken(localstorage);
      //const Pathpermissions = localStorage.getItem("Permissions");
      const retrievedObject = JSON.parse(Pathpermissions);
      let foundMatch = false;
      for (const key in retrievedObject) {
        this.trimmedKey = (key.trim()); // Trim the key
        if (route.url[0].path.trim().toLocaleLowerCase() === this.trimmedKey.toLocaleLowerCase()) {
          foundMatch = true;

          // Encryption
          //localStorage.setItem('currentURL', state.url);
          localStorage.setItem('currentURL', this.tokenEncryptDecrypt.encryptToken(state.url));


        }
      }
      return foundMatch;
    }
    catch {

    }
  }

  doLogout() {
    return new Promise<void>((resolve, reject) => {
      this.afAuth.signOut();
      resolve();
    });
  }

}
