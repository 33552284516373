import { Injectable } from "@angular/core";
import { ToastrService } from "ngx-toastr";

// import { isNullOrUndefined } from "util";
import { Group } from "@progress/kendo-drawing";
import { JsonPipe } from "@angular/common";
import { CallserviceService } from "src/app/CallServices/callservice.service";
import { TokenEncryptionService } from "src/app/CallServices/token-encryption.service";
import { Utility } from "src/app/ModelFinancialMetrics/CommonService/utility";


@Injectable({
  providedIn: 'root'
})
export class FilterDataService {
  constructor(private toaster: ToastrService, private api: CallserviceService, private tokenEncryptDecrypt: TokenEncryptionService) {
    this.clsUtility = new Utility(toaster);
  }
  clsUtility: Utility;

  GetUserGroups(): any {
    try {
      let AllGroup: {
        groupid: string;
        groupname: string;
        tin: string;
        ccn: string;
        groupcode: string;
        qpathwaysid: string;
        isparent: boolean;
      } = {
        groupid: "0",
        groupname: "All",
        tin: null,
        ccn: null,
        groupcode: "All",
        qpathwaysid: "",
        isparent: true,
      };

      let allGroupPractice: any;
      let Groups: any;
      allGroupPractice = JSON.parse(this.tokenEncryptDecrypt.decryptToken(sessionStorage.getItem("GroupPractice")));  //Decryption//JSON.parse(sessionStorage.getItem("GroupPractice"));
      Groups = [];
      if (!isNullOrUndefined(allGroupPractice)) {
        for (let i = 0; i < allGroupPractice.length; i++) {
          if (allGroupPractice[i].isparent == true) {
            Groups.push(allGroupPractice[i]);
          }
        }

        if (!isNullOrUndefined(Groups) && Groups.length > 0) {
          if (Groups.length > 1) {
            Groups.unshift(AllGroup);
          }
        }
      }

      // Hide value group
      this.ListPractices = JSON.parse(this.tokenEncryptDecrypt.decryptToken(sessionStorage.getItem("listwithAllPractice")));
      let dummyGroup = [];

      Groups.forEach(element => {
        for (let i = 0; i < this.ListPractices.length; i++) {
          const element1 = this.ListPractices[i];

          if (element.groupcode == "All" && element.groupname == "All") {
            dummyGroup.push(element);
            break; // Breaks out of the inner loop
          }

          if (element.isparent == true) {
            if (element.groupid == element1.parentgroupid) {
              dummyGroup.push(element);
              break; // Breaks out of the inner loop
            }
          }
        }
      });
      Groups = dummyGroup;
      // End


      Groups.sort(this.GetSortOrder("groupname"));
      return Groups;
    } catch (error) {
      this.clsUtility.LogError(error);
    }
  }
  Currentdataset: string;
  public practicelist: any = [];
  ListPractices: any = [];
  GetUserPractices(Parentid: any = null): any {
    try {
      let AllPractices: {
        ccn: string;
        groupcode: string;
        groupid: string;
        groupname: string;
        qpathwaysid: string;
        tin: string;
        isparent: boolean;
      } = {
        ccn: "",
        groupcode: "All",
        groupid: "0",
        groupname: "All",
        qpathwaysid: "",
        tin: "",
        isparent: false,
      };

      let allGroupPractice: any;
      let Practices: any;
      allGroupPractice = JSON.parse(this.tokenEncryptDecrypt.decryptToken(sessionStorage.getItem("GroupPractice")));//JSON.parse(sessionStorage.getItem("GroupPractice"));
      //let MappedList = JSON.parse( sessionStorage.getItem("GroupWithMappedPractice")); 
      let MappedList = JSON.parse(this.tokenEncryptDecrypt.decryptToken(sessionStorage.getItem("GroupWithMappedPractice")))  //Decryption
      let BQpractices: any;
      BQpractices = JSON.parse(this.tokenEncryptDecrypt.decryptToken(sessionStorage.getItem("BQPractices")));  //Decryption //JSON.parse(sessionStorage.getItem("BQPractices"));
      // console.log('BQPractices',BQpractices)
      Practices = [];
      if (!isNullOrUndefined(allGroupPractice)) {
        for (let i = 0; i < allGroupPractice.length; i++) {
          if (allGroupPractice[i].isparent == false) {
            for (let j = 0; j < BQpractices.length; j++) {
              if (allGroupPractice[i].groupcode.toUpperCase() == BQpractices[j].clientkey.toUpperCase()) {
                if (isNullOrUndefined(Parentid)) {
                  Practices.push(allGroupPractice[i]);
                } else if (allGroupPractice[i].parentid == Parentid) {
                  Practices.push(allGroupPractice[i]);
                }
              }
            }

          } else {
            let sPractices: {
              ccn: string;
              groupcode: string;
              groupid: string;
              groupname: string;
              qpathwaysid: string;
              tin: string;
              isparent: boolean;
            } = {
              ccn: "",
              groupcode: allGroupPractice[i].groupcode,
              groupid: allGroupPractice[i].groupid,
              groupname: "All " + allGroupPractice[i].groupname,
              qpathwaysid: "",
              tin: "",
              isparent: true,
            };

            Practices.unshift(sPractices);
          }
        }
        for (let i = 0; i < MappedList.length; i++) {
          if (MappedList[i].isparent == false && Practices.length > 1) {
            Practices.unshift(AllPractices);
            break;
          }

          if (Practices.length > 1) {
            Practices.unshift(AllPractices);
            break;
          }
        }

        // if (!isNullOrUndefined(Practices) && Practices.length > 0) {
        //   if (!isNullOrUndefined(MappedList) && MappedList.length > 0) {
        //     let index = MappedList.findindex((x) => x.isparent == false);
        //     if (index >= 0) {
        //       Practices.unshift(AllPractices);
        //     }
        //   }
        //   // if (Practices.length > 1) {
        //   //   Practices.unshift(AllPractices);
        //   // }
        // }
      }

      // let allGroupPractice: any;
      // let Practices: any;
      // allGroupPractice = JSON.parse(sessionStorage.getItem("GroupWithMappedPractice"));
      // Practices = [];
      // if (!isNullOrUndefined(allGroupPractice)) {
      //   for (let i = 0; i < allGroupPractice.length; i++) {
      //     if (allGroupPractice[i].isparent == false) {
      //       if (isNullOrUndefined(Parentid)) {
      //         Practices.push(allGroupPractice[i]);
      //       } else if (allGroupPractice[i].groupid == Parentid) {
      //         Practices.push(allGroupPractice[i]);
      //       }
      //     }
      //   }
      // }

      Practices.sort(this.GetSortOrder("groupname"));

      // try value practices
      this.ListPractices = JSON.parse(this.tokenEncryptDecrypt.decryptToken(sessionStorage.getItem("listwithAllPractice")));
      let dummypractice = [];
      Practices.forEach(element => {
        for (let i = 0; i < this.ListPractices.length; i++) {
          const element1 = this.ListPractices[i];

          if (element.isparent == false) {
            dummypractice.push(element);
            break; // Breaks out of the inner loop
          }

          if (element.isparent == true) {
            if (element.groupid == element1.parentgroupid) {
              dummypractice.push(element);
              break; // Breaks out of the inner loop
            }
          }
        }
      });
      Practices = dummypractice;
      //End

      // For data masking on staging environment we are changing the original names with the dummy names 
      // the dummy names comes from the pycharm in practicelist 
      this.Currentdataset = this.tokenEncryptDecrypt.decryptToken(sessionStorage.getItem("currentEnvironment"));
      if (this.Currentdataset == "Staging") {
        this.practicelist = JSON.parse(this.tokenEncryptDecrypt.decryptToken(sessionStorage.getItem("ListofPractice"))); //Decryption  JSON.parse(sessionStorage.getItem("ListofPractice"));
        // console.log('print practicelist', this.practicelist)
        for (let i = 0; i < Practices.length; i++) {
          for (let j = 0; j < this.practicelist.length; j++) {
            if (Practices[i].groupcode == this.practicelist[j].groupcode) {
              Practices[i].groupname = this.practicelist[j].groupname;
              break;
            }
          }
        }
      }


      return Practices;
    } catch (error) {
      this.clsUtility.LogError(error);
    }
  }

  //Group Practice Filter for MIU
  GetUserMIUPracticesold(Parentid: any = null): any {
    try {
      let allMIUGroupPractice: any;
      let MIUPractices: any;
      allMIUGroupPractice = JSON.parse(this.tokenEncryptDecrypt.decryptToken(sessionStorage.getItem("MIUGroupPractice")));//JSON.parse(sessionStorage.getItem("GroupPractice"));
      let MIUPracticeList: any;
      MIUPracticeList = JSON.parse(this.tokenEncryptDecrypt.decryptToken(sessionStorage.getItem("MIUPracticeList")));  //Decryption //JSON.parse(sessionStorage.getItem("BQPractices"));
      MIUPractices = [];
      if (!isNullOrUndefined(allMIUGroupPractice)) {
        for (let i = 0; i < allMIUGroupPractice.length; i++) {
          if (allMIUGroupPractice[i].isparent == false) { //to get single practices list
            if (allMIUGroupPractice[i].groupcode == 'MIU') {
              allMIUGroupPractice[i].groupname = 'MIU'
            }
            MIUPractices.push(allMIUGroupPractice[i])
            // MIUPracticeList.forEach(group => {
            //   if (group.groupcode === 'MIU' || group.groupcode === 'UC') {
            //     if(group.groupcode === 'MIU')
            //     {
            //       group.groupname='MIU'
            //     }
            //     MIUPractices.push(group);
            //   }
            // });
            // for (let j = 0; j < inew.length; j++) {

            //   if (allMIUGroupPractice[i].groupcode == MIUPracticeList[j].groupname) {

            //     if (isNullOrUndefined(Parentid)) {
            //       MIUPractices.push(allMIUGroupPractice[i]);
            //     } else if (allMIUGroupPractice[i].parentid == Parentid) {
            //       MIUPractices.push(allMIUGroupPractice[i]);
            //     }
            //   }
            // }

          } else {  //if parent is true for MIU_UC_GRP and to get childpractices separate
            let sPractices: { //to get Group practices list
              ccn: string;
              groupcode: string;
              groupid: string;
              groupname: string;
              qpathwaysid: string;
              tin: string;
              isparent: boolean;
            } = {
              ccn: "",
              groupcode: allMIUGroupPractice[i].groupcode,
              groupid: allMIUGroupPractice[i].groupid,
              groupname: "All " + allMIUGroupPractice[i].groupname,
              qpathwaysid: "",
              tin: "",
              isparent: true,
            };

            MIUPractices.unshift(sPractices);
          }
        }
      }
      MIUPractices.sort(this.GetSortOrder("groupname"));
      // console.log('MIUPractices',MIUPractices);
      return MIUPractices;
    } catch (error) {
      this.clsUtility.LogError(error);
    }
  }


  GetUserMIUPractices(Parentid: any = null): any {
    try {
      let allMIUGroupPractice: any;
      let MIUPractices: any;
      allMIUGroupPractice = JSON.parse(this.tokenEncryptDecrypt.decryptToken(sessionStorage.getItem("MIUGroupPractice")));//JSON.parse(sessionStorage.getItem("GroupPractice"));
      let MIUPracticeList: any;
      MIUPracticeList = JSON.parse(this.tokenEncryptDecrypt.decryptToken(sessionStorage.getItem("MIUPracticeList")));  //Decryption //JSON.parse(sessionStorage.getItem("BQPractices"));
      MIUPractices = [];
      if (!isNullOrUndefined(allMIUGroupPractice)) {
        for (let i = 0; i < allMIUGroupPractice.length; i++) {
          if (allMIUGroupPractice[i].isparent == false) { //to get single practices list
            for (let j = 0; j < MIUPracticeList.length; j++) {
              if (allMIUGroupPractice[i].groupcode == MIUPracticeList[j].groupcode) {
                if (isNullOrUndefined(Parentid)) {
                  MIUPractices.push(allMIUGroupPractice[i]);
                } else if (allMIUGroupPractice[i].parentid == Parentid) {
                  MIUPractices.push(allMIUGroupPractice[i]);
                }
              }
            }
          } else {  //if parent is true for MIU_UC_GRP and to get childpractices separate
            let sPractices: { //to get Group practices list
              ccn: string;
              groupcode: string;
              groupid: string;
              groupname: string;
              qpathwaysid: string;
              tin: string;
              isparent: boolean;
            } = {
              ccn: "",
              groupcode: allMIUGroupPractice[i].groupcode,
              groupid: allMIUGroupPractice[i].groupid,
              //groupname: "All " + allMIUGroupPractice[i].groupname,
              groupname: allMIUGroupPractice[i].groupname,
              qpathwaysid: "",
              tin: "",
              isparent: true,
            };
            MIUPractices.unshift(sPractices);
          }
        }
      }
      MIUPractices.sort(this.GetSortOrder("groupname"));
      // console.log('MIUPractices', MIUPractices)
      return MIUPractices;

    }
    catch (error) {
      this.clsUtility.LogError(error);
    }
  }

  GetUserTrendPractices(Parentid: any = null): any {
    try {
      let trendPractices: any;
      let MIUPracticeList: any;
      trendPractices = [];
      MIUPracticeList = JSON.parse(this.tokenEncryptDecrypt.decryptToken(sessionStorage.getItem("MIUPracticeList")));  //Decryption //JSON.parse(sessionStorage.getItem("BQPractices"));

      MIUPracticeList.forEach(group => {
        if (group.groupname === 'All MSK URO' || group.groupname === 'MSK' || group.groupname === 'URO') {
          trendPractices.push(group);
        }
      });

      trendPractices.sort(this.GetSortOrder("groupname"));
      return trendPractices;

    }
    catch (error) {
      this.clsUtility.LogError(error);
    }
  }

  GetSortOrder(prop) {
    return function (a, b) {
      if (a[prop] > b[prop]) {
        return 1;
      } else if (a[prop] < b[prop]) {
        return -1;
      }
      return 0;
    }
  }
  UserLastSelection = new Array();
  public getUserLastSelection() {
    let param: { userid: string; envname: string } = {
      userid: "",
      envname: "",
    };
    param.envname = this.tokenEncryptDecrypt.decryptToken(sessionStorage.getItem("currentEnvironment")).toString();
    param.userid = this.tokenEncryptDecrypt.decryptToken(sessionStorage.getItem("userid")).toString();
    this.api.getFunctionName("UserGroupRetrieve");
    const UserSelectionCall = this.api.BindDataFMetrics(param).share();
    UserSelectionCall.map((res) => res.json()).subscribe((res) => {
      this.setUserLastSelection(res);
    });
  }

  public setUserLastSelection(res) {
    if (!isNullOrUndefined(res)) {
      this.UserLastSelection = res;

      //sessionStorage.setItem("UserLastSelection", JSON.stringify(this.UserLastSelection));  
      sessionStorage.setItem('UserLastSelection', this.tokenEncryptDecrypt.encryptToken(JSON.stringify(this.UserLastSelection)));   //Encryption

    }
  }
  Costom_Filtor(body: any) {
    let Currentdataset = body.Currentdataset;
    let operationtype = body.operationtype;
    let userid = body.userid;
    let username = body.username;
    let dashboardname = body.dashboardname;
    let Start_Date = body.Start_Date;
    let End_Date = body.End_Date;
    let Date_Type = body.Date_Type;
    let Procedure_Type = body.Procedure_Type;
    let Provider_Type = body.Provider_Type;
    let Insurance_Type = body.Insurance_Type;
    let GroupBy = body.GroupBy;
    let Include_Mod = body.Include_Mod;
    let Skip_DOS_before = body.Skip_DOS_before;
    let Skip_DOS_after = body.Skip_DOS_after;
    let ProviderValue = body.ProviderValue;
    let InsuranceValue = body.InsuranceValue;
    let ProcedureValue = body.ProcedureValue;
    let Facility = body.Facility;
    let Practicelist = body.Practicelist;
    let selectedClientkey = body.clientkey;
    let min_balance = body.Min_Balance
    let Max_Balance = body.Max_Balance;
    let InsuranceClaimFollowUp = body.InsuranceClaimFollowUp;
    let PatientAccountFollowUp = body.PatientAccountFollowUp;
    let as_of_date = body.as_of_date
    let flag = body.flag;
    let RCM_team = body.RCM_team;
    let RCM_SPAN = body.RCM_SPAN;
    let diagnosiscode = body.diagnosiscode;

    return `call ${Currentdataset}.SP_Custom_Filters_Common("${operationtype}", "${Currentdataset}", "${userid}","${username}","${dashboardname}","${selectedClientkey}","${Practicelist}", "${Start_Date}", "${End_Date}", "${Date_Type}", "${Procedure_Type}", "${ProcedureValue}","${Provider_Type}", "${ProviderValue}","${Insurance_Type}", "${InsuranceValue}","${Facility}", "${GroupBy}", "${Include_Mod}","${Skip_DOS_before}","${Skip_DOS_after}","${InsuranceClaimFollowUp}","${PatientAccountFollowUp}",  "${min_balance}","${Max_Balance}","${as_of_date}","${flag}","${RCM_team}", "${RCM_SPAN}","${diagnosiscode}")`;
  }

  GET_Filtor(body: any) {
    let currentdataset = body.currentdataset;
    let module = body.module;
    let userfullname = body.userfullname;
    let userid = body.userid;
    let parame1 = body.parame1;
    let parame2 = body.parame2;
    let parame3 = body.parame3;
    let parame4 = body.parame4;
    let parame5 = body.parame5;
    let parame6 = body.parame6;
    let parame7 = body.parame7;
    let parame8 = body.parame8;
    let parame9 = body.parame9;
    let parame10 = body.parame10;
    let parame11 = body.parame11;
    let parame12 = body.parame12;
    let parame13 = body.parame13;
    let parame14 = body.parame14;
    let parame15 = body.parame15;
    let parame16 = body.parame16;
    let parame17 = body.parame17;
    let parame18 = body.parame18;
    let parame19 = body.parame19;
    let parame20 = body.parame20;
    let parame21 = body.parame21;
    let parame22 = body.parame22;
    let parame23 = body.parame23;
    let parame24 = body.parame24;
    let parame25 = body.parame25;
    let parame26 = body.parame26
    let parame27 = body.parame27

    let transactiontype = "GET";

    return `call ${currentdataset}.SP_Custom_Filters_Common('${transactiontype}', '${currentdataset}', '${userid}', '${userfullname}', '${module}', '${parame1}', '${parame2}', '${parame3}', '${parame4}', '${parame5}', '${parame6}', '${parame7}', '${parame8}', '${parame9}', '${parame10}', '${parame11}', '${parame12}', '${parame13}', '${parame14}', '${parame15}', '${parame16}','${parame17}','${parame18}','${parame19}','${parame20}','${parame21}','${parame22}','${parame23}','${parame24}','${parame25}')`;
  }
}
function isNullOrUndefined(value: any): boolean { return value === null || value === undefined; }

