import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { ToastrModule } from "ngx-toastr";
import { CookieService } from "ngx-cookie-service";
import { routing } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { SidebarComponent } from "./ModelFinancialMetrics/CommonService/sidebar/sidebar.component";
import { HeaderComponent } from "./ModelFinancialMetrics/CommonService/header/header.component";
import { AuthService } from "./AuthGuard/auth.service";
import { AuthGuard } from "./AuthGuard/auth.guard";
import { FormsModule } from "@angular/forms";
// Imports the Button module
import { KendoModule } from "./kendo.module";
import { MqloginComponent } from "./mqlogin/mqlogin.component";
import { SetsidComponent } from "./setsid/setsid.component";
import { HttpModule } from "@angular/http";
import { Ng2GoogleChartsModule } from "ng2-google-charts";
import { Permission } from "./CallServices/Permission";
import { LoginLayoutComponent } from "./layout/login-layout/login-layout.component";
import { HomeLayoutComponent } from "./layout/home-layout/home-layout.component";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { DatePipe } from "@angular/common";
import { NgbActiveModal, NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { NgIdleKeepaliveModule } from "@ng-idle/keepalive";
// Import ng-circle-progress
import { NgCircleProgressModule } from 'ng-circle-progress';
import { NameInitialPipe } from "./name-initials.pipe";
import { ServiceConfigPermission } from "./CallServices/Permission";
import { HttpClientModule } from '@angular/common/http';
import { SharedModule } from './shared/shared.module';
import { AngularMultiSelectModule } from "angular2-multiselect-dropdown";
import { ExcelService } from "./Modelqdashboards/QAdmin/query-configuration/excel.service";
import { CreatedProjectsComponent } from "./Modelqdashboards/QAdmin/created-projects/created-projects.component";

import { environment } from "src/environments/environment";
import { AngularFireModule } from '@angular/fire/compat';
import { AngularFireAuthModule } from '@angular/fire/compat/auth';
import { AngularFirestoreModule } from '@angular/fire/compat/firestore';
import { AngularFireStorageModule } from '@angular/fire/compat/storage';
import { AngularFireDatabaseModule } from '@angular/fire/compat/database';
import { dashboardList } from "./ModelFinancialMetrics/CommonService/dashboard-list";

@NgModule({
  declarations: [
    AppComponent,
    SidebarComponent,
    HeaderComponent,
    MqloginComponent,
    SetsidComponent,
    LoginLayoutComponent,
    HomeLayoutComponent,
    NameInitialPipe,
  ],
  imports: [
    SharedModule,
    FormsModule,
    Ng2GoogleChartsModule,
    HttpModule,
    BrowserModule,
    routing,
    FormsModule,
    KendoModule,
    NgbModule,
    AngularMultiSelectModule,
    NgIdleKeepaliveModule.forRoot(),
    HttpClientModule,
    ToastrModule.forRoot({
      timeOut: 4000,
      positionClass: "toast-top-center",
      preventDuplicates: true,
    }),
    BrowserAnimationsModule,
    // Specify ng-circle-progress as an import
    NgCircleProgressModule.forRoot({}),

    AngularFireModule.initializeApp(environment.firebase),
    AngularFireAuthModule,
    AngularFirestoreModule,
    AngularFireStorageModule,
    AngularFireDatabaseModule,
  ],

  providers: [DatePipe, AuthService, AuthGuard, ExcelService, CookieService, Permission, CreatedProjectsComponent, NgbActiveModal, NameInitialPipe, ServiceConfigPermission, dashboardList],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor() {
    // console.log("app module loaded");
  }
}
