<!-- Side Nav START -->
<div class="side-nav">
  <div class="side-nav-inner">
    <div class="side-nav-logo">
      <a href="#">
        <div class="logo logo-dark" style="background-image: url(../../../assets/img/QInsight-logo.png)">
        </div>
        <div class="logo logo-white">
          <img src="../../../assets/img/QInsight-logo.png" alt="" width="180" class="pt-4" />
        </div>
      </a>
      <div class="mobile-toggle side-nav-toggle">
        <a>
          <i class="fa fa-arrow-left"></i>
        </a>
      </div>
    </div>
    <ul class="side-nav-menu scrollable">
      <li class="nav-item dropdown" [hidden]="!MetricsHomeMenu" *ngIf="permission.home">
        <a class="dropdown-toggle home" routerLink="/qdashboards/home" routerLinkActive="active">
          <span class="icon-holder">
            <i class="mdi mdi-home"></i>
          </span>
          <span class="title">Home</span>
        </a>
      </li>
      <li class="nav-item dropdown" [hidden]="!ProjectsMenu">
        <a class="dropdown-toggle">
          <span class="icon-holder" style="transform: rotate(180deg); line-height: 28px;">
            <i class="ri-bar-chart-box-line"></i>
          </span>
          <span class="title">Projects</span>
          <span class="arrow">
            <i class="fa fa-angle-right"></i>
          </span>
        </a>
        <ul class="dropdown-menu">
          <li *ngIf="permission.MyProjects">
            <a routerLink="/qdashboards/MyProjects">
              My Projects
            </a>
          </li>
          <li *ngIf="permission.ProjectInsight">
            <a routerLink="/qdashboards/ProjectInsight">
              Project Insight
            </a>
          </li>
        </ul>
      </li>

      <div class="dropdown-divider" *ngIf="MetricsHomeMenu"></div>

      <li class="nav-item dropdown" [hidden]="!MetricsmainMenu">
        <a class="dropdown-toggle" (click)="clearsearchvar()">
          <span class="icon-holder">
            <i class="ri-currency-line"></i>
          </span>
          <span class="title">Financial Metrics</span>
          <span class="arrow">
            <i class="fa fa-angle-right"></i>
          </span>
        </a>
        <ul class="dropdown-menu">
          <!-- *ngIf="financialsearchbarhideshow" -->
          <div class="sidebar-search">
            <div style="position: relative; display: inline-block;">
              <input type="text" placeholder="Search..." [(ngModel)]="searchTermFinancial"
                (input)="financialfilterItems()" style="margin-left: 47px;" />
              <span *ngIf="searchTermFinancial" (click)="clearsearchvar()"
                style="position: absolute; right: 10px; top: 50%; transform: translateY(-50%); cursor: pointer; font-size: 14px; color: #aaa;">
                ✖
              </span>
            </div>
          </div>
          <ng-container *ngFor="let item of financialdashboardList">
            <li [routerLink]="item.route" routerLinkActive="active" *ngIf="permission[item.permission]"
              class="nav-item">
              <a>{{ item.label }}
                <div class="badge bg-green-lt float-right" *ngIf="item.New == true">New</div>
              </a>
            </li>
          </ng-container>
        </ul>
      </li>
      <li class="nav-item dropdown" [hidden]="!RCMMainMenu">
        <a class="dropdown-toggle" (click)="clearsearchvar()">
          <span class="icon-holder">
            <i class="ri-exchange-dollar-fill"></i>
          </span>
          <span class="title">RCM</span>
          <span class="arrow">
            <i class="fa fa-angle-right"></i>
          </span>
        </a>
        <ul class="dropdown-menu">
          <!-- *ngIf="rcmsearchbarhideshow" -->
          <div class="sidebar-search">
            <div style="position: relative; display: inline-block;">
              <input type="text" placeholder="Search..." [(ngModel)]="searchTermRcm" (input)="rcmfilterMenuItems()"
                style="margin-left: 47px;" />
              <span *ngIf="searchTermRcm" (click)="clearsearchvar()"
                style="position: absolute; right: 10px; top: 50%; transform: translateY(-50%); cursor: pointer; font-size: 14px; color: #aaa;">
                ✖
              </span>
            </div>
          </div>
          <ng-container *ngFor="let item of rcmdashboardList">
            <li [routerLink]="item.route" routerLinkActive="active" *ngIf="permission[item.permission]"
              class="nav-item">
              <a>{{ item.label }}
                <div class="badge bg-green-lt float-right" *ngIf="item.New == true">New</div>
              </a>
            </li>
          </ng-container>
        </ul>
      </li>
      <li class="nav-item dropdown" [hidden]="!Biotechmainmenu">
        <a class="dropdown-toggle" (click)="clearsearchvar()">
          <span class="icon-holder">
            <i class="mdi mdi-dna"></i>
          </span>
          <span class="title">BIOTECH</span>
          <span class="arrow">
            <i class="fa fa-angle-right"></i>
          </span>
        </a>
        <ul class="dropdown-menu">
          <!-- *ngIf="biotechsearchbarhideshow" -->
          <div class="sidebar-search">
            <div style="position: relative; display: inline-block;">
              <input type="text" placeholder="Search..." [(ngModel)]="searchTermBiotech" (input)="biotechfilterItems()"
                style="margin-left: 47px;" />
              <span *ngIf="searchTermBiotech" (click)="clearsearchvar()"
                style="position: absolute; right: 10px; top: 50%; transform: translateY(-50%); cursor: pointer; font-size: 14px; color: #aaa;">
                ✖
              </span>
            </div>
          </div>
          <ng-container *ngFor="let item of biotechdashboardList">
            <li [routerLink]="item.route" routerLinkActive="active" *ngIf="permission[item.permission]"
              class="nav-item">
              <a>{{ item.label }}
                <div class="badge bg-green-lt float-right" *ngIf="item.New == true">New</div>
              </a>
            </li>
          </ng-container>
        </ul>
      </li>
      <li class="nav-item dropdown" [hidden]="!BanqMainMenu">
        <a class="dropdown-toggle" (click)="clearsearchvar()">
          <span class="icon-holder">
            <i class="ri-organization-chart"></i>
          </span>
          <span class="title">HUB</span>
          <span class="arrow">
            <i class="fa fa-angle-right"></i>
          </span>
        </a>
        <ul class="dropdown-menu">
          <!-- *ngIf="hubsearchbarhideshow" -->
          <div class="sidebar-search">
            <div style="position: relative; display: inline-block;">
              <input type="text" placeholder="Search..." [(ngModel)]="searchTermHub" (input)="hubfilterItems()"
                style="margin-left: 47px;" />
              <span *ngIf="searchTermHub" (click)="clearsearchvar()"
                style="position: absolute; right: 10px; top: 50%; transform: translateY(-50%); cursor: pointer; font-size: 14px; color: #aaa;">
                ✖
              </span>
            </div>
          </div>
          <ng-container *ngFor="let item of hubdashboardList">
            <li [routerLink]="item.route" routerLinkActive="active" *ngIf="permission[item.permission]"
              class="nav-item">
              <a>{{ item.label }}
                <div class="badge bg-green-lt float-right" *ngIf="item.New == true">New</div>
              </a>
            </li>
          </ng-container>
        </ul>
      </li>
      <li class="nav-item dropdown" [hidden]="!MIUMainMenu">
        <a class="dropdown-toggle" (click)="clearsearchvar()">
          <span class="icon-holder">
            <i class="fa fa-hand-holding-medical"></i>
          </span>
          <span class="title">MIU</span>
          <span class="arrow">
            <i class="fa fa-angle-right"></i>
          </span>
        </a>
        <ul class="dropdown-menu">
          <!-- *ngIf="miusearchbarhideshow" -->
          <div class="sidebar-search">
            <div style="position: relative; display: inline-block;">
              <input type="text" placeholder="Search..." [(ngModel)]="searchTermMiu" (input)="miufilterItems()"
                style="margin-left: 47px;" />
              <span *ngIf="searchTermMiu" (click)="clearsearchvar()"
                style="position: absolute; right: 10px; top: 50%; transform: translateY(-50%); cursor: pointer; font-size: 14px; color: #aaa;">
                ✖
              </span>
            </div>
          </div>
          <ng-container *ngFor="let item of miudashboardList">
            <li [routerLink]="item.route" routerLinkActive="active" *ngIf="permission[item.permission]"
              class="nav-item">
              <a>{{ item.label }}
                <div class="badge bg-green-lt float-right" *ngIf="item.New == true">New</div>
              </a>
            </li>
          </ng-container>
        </ul>
      </li>
      <li class="nav-item dropdown" [hidden]="!mainMonitor">
        <a class="dropdown-toggle" (click)="clearsearchvar()">
          <span class="icon-holder">
            <i class="fab fa-battle-net"></i>
          </span>
          <span class="title">Monitor</span>
          <span class="arrow">
            <i class="fa fa-angle-right"></i>
          </span>
        </a>
        <ul class="dropdown-menu">
          <!-- *ngIf="monitorsearchbarhideshow" -->
          <div class="sidebar-search">
            <div style="position: relative; display: inline-block;">
              <input type="text" placeholder="Search..." [(ngModel)]="searchTermMonitor" (input)="monitorfilterItems()"
                style="margin-left: 47px;" />
              <span *ngIf="searchTermMonitor" (click)="clearsearchvar()"
                style="position: absolute; right: 10px; top: 50%; transform: translateY(-50%); cursor: pointer; font-size: 14px; color: #aaa;">
                ✖
              </span>
            </div>
          </div>
          <ng-container *ngFor="let item of monitordashboardList">
            <li [routerLink]="item.route" routerLinkActive="active" *ngIf="permission[item.permission]"
              class="nav-item">
              <a>{{ item.label }}
                <div class="badge bg-green-lt float-right" *ngIf="item.New == true">New</div>
              </a>
            </li>
          </ng-container>
        </ul>
      </li>
      <li class="nav-item dropdown" [hidden]="!AdhocMainMenu">
        <a class="dropdown-toggle" (click)="clearsearchvar()">
          <span class="icon-holder">
            <i class="mdi mdi-chart-scatter-plot-hexbin"></i>
          </span>
          <span class="title">Adhoc Reports</span>
          <span class="arrow">
            <i class="fa fa-angle-right"></i>
          </span>
        </a>
        <ul class="dropdown-menu">
          <!-- *ngIf="adhocsearchbarhideshow" -->
          <div class="sidebar-search">
            <div style="position: relative; display: inline-block;">
              <input type="text" placeholder="Search..." [(ngModel)]="searchTermAdhoc" (input)="adhocfilterItems()"
                style="margin-left: 47px;" />
              <span *ngIf="searchTermAdhoc" (click)="clearsearchvar()"
                style="position: absolute; right: 10px; top: 50%; transform: translateY(-50%); cursor: pointer; font-size: 14px; color: #aaa;">
                ✖
              </span>
            </div>
          </div>
          <ng-container *ngFor="let item of adhocdashboardList">
            <li [routerLink]="item.route" routerLinkActive="active" *ngIf="permission[item.permission]"
              class="nav-item">
              <a>{{ item.label }}
                <div class="badge bg-green-lt float-right" *ngIf="item.New == true">New</div>
              </a>
            </li>
          </ng-container>
        </ul>
      </li>
      <li class="nav-item dropdown" [hidden]="!ClinicalMain">
        <a class="dropdown-toggle" (click)="clearsearchvar()">
          <span class="icon-holder">
            <i class="ri-hospital-line"></i>
          </span>
          <span class="title">Clinical</span>
          <span class="arrow">
            <i class="fa fa-angle-right"></i>
          </span>
        </a>
        <ul class="dropdown-menu">
          <!-- *ngIf="clinicalsearchbarhideshow" -->
          <div class="sidebar-search">
            <div style="position: relative; display: inline-block;">
              <input type="text" placeholder="Search..." [(ngModel)]="searchTermClinical"
                (input)="clinicalfilterItems()" style="margin-left: 47px;" />
              <span *ngIf="searchTermClinical" (click)="clearsearchvar()"
                style="position: absolute; right: 10px; top: 50%; transform: translateY(-50%); cursor: pointer; font-size: 14px; color: #aaa;">
                ✖
              </span>
            </div>
          </div>
          <ng-container *ngFor="let item of clinicaldashboardList">
            <li [routerLink]="item.route" routerLinkActive="active" *ngIf="permission[item.permission]"
              class="nav-item">
              <a>{{ item.label }}
                <div class="badge bg-green-lt float-right" *ngIf="item.New == true">New</div>
              </a>
            </li>
          </ng-container>
        </ul>
      </li>
      <li class="nav-item dropdown" [hidden]="!QBOTMainMenu">
        <a class="dropdown-toggle" (click)="clearsearchvar()">
          <span class="icon-holder">
            <i class="ti ti-support"></i>
          </span>
          <span class="title">QBots Monitoring</span>
          <span class="arrow">
            <i class="fa fa-angle-right"></i>
          </span>
        </a>
        <ul class="dropdown-menu">
          <!-- *ngIf="qbotssearchbarhideshow" -->
          <div class="sidebar-search">
            <div style="position: relative; display: inline-block;">
              <input type="text" placeholder="Search..." [(ngModel)]="searchTermQbots" (input)="qbotsfilterItems()"
                style="margin-left: 47px;" />
              <span *ngIf="searchTermQbots" (click)="clearsearchvar()"
                style="position: absolute; right: 10px; top: 50%; transform: translateY(-50%); cursor: pointer; font-size: 14px; color: #aaa;">
                ✖
              </span>
            </div>
          </div>
          <ng-container *ngFor="let item of qbotsdashboardList">
            <li [routerLink]="item.route" routerLinkActive="active" *ngIf="permission[item.permission]"
              class="nav-item">
              <a>{{ item.label }}
                <div class="badge bg-green-lt float-right" *ngIf="item.New == true">New</div>
              </a>
            </li>
          </ng-container>
        </ul>
      </li>
      <li class="nav-item dropdown" [hidden]="!QAdminMainmenu">
        <a class="dropdown-toggle" (click)="clearsearchvar()">
          <span class="icon-holder">
            <i class="ri-settings-5-line"></i>
          </span>
          <span class="title">Configurations</span>
          <span class="arrow">
            <i class="fa fa-angle-right"></i>
          </span>
        </a>
        <ul class="dropdown-menu">
          <!-- *ngIf="configsearchbarhideshow" -->
          <div class="sidebar-search">
            <div style="position: relative; display: inline-block;">
              <input type="text" placeholder="Search..." [(ngModel)]="searchTermConfig" (input)="configfilterItems()"
                style="margin-left: 47px;" />
              <span *ngIf="searchTermConfig" (click)="clearsearchvar()"
                style="position: absolute; right: 10px; top: 50%; transform: translateY(-50%); cursor: pointer; font-size: 14px; color: #aaa;">
                ✖
              </span>
            </div>
          </div>
          <ng-container *ngFor="let item of configdashboardList">
            <li [routerLink]="item.route" routerLinkActive="active" *ngIf="permission[item.permission]"
              class="nav-item">
              <a>{{ item.label }}
                <div class="badge bg-green-lt float-right" *ngIf="item.New == true">New</div>
              </a>
            </li>
          </ng-container>
        </ul>
      </li>
      <li class="nav-item dropdown" [hidden]="!MLMainmenu">
        <a class="dropdown-toggle" (click)="clearsearchvar()">
          <span class="icon-holder">
            <i class="mdi mdi-robot"></i>
          </span>
          <span class="title">Machine Learning</span>
          <span class="arrow">
            <i class="fa fa-angle-right"></i>
          </span>
        </a>
        <ul class="dropdown-menu">
          <!-- *ngIf="mlsearchbarhideshow" -->
          <div class="sidebar-search">
            <div style="position: relative; display: inline-block;">
              <input type="text" placeholder="Search..." [(ngModel)]="searchTermMl" (input)="mlfilterItems()"
                style="margin-left: 47px;" />
              <span *ngIf="searchTermMl" (click)="clearsearchvar()"
                style="position: absolute; right: 10px; top: 50%; transform: translateY(-50%); cursor: pointer; font-size: 14px; color: #aaa;">
                ✖
              </span>
            </div>
          </div>
          <ng-container *ngFor="let item of mldashboardList">
            <li [routerLink]="item.route" routerLinkActive="active" *ngIf="permission[item.permission]"
              class="nav-item">
              <a>{{ item.label }}
                <div class="badge bg-green-lt float-right" *ngIf="item.New == true">New</div>
              </a>
            </li>
          </ng-container>
        </ul>
      </li>

      <div class="dropdown-divider"></div>

      <li class="nav-item dropdown" [hidden]="!valueMainMenu">
        <a class="dropdown-toggle" (click)="clearsearchvar()">
          <span class="icon-holder">
            <i class="k-icon k-i-plus-circle" style="font-size: 22px;"></i>
          </span>
          <span class="title">Value</span>
          <span class="arrow">
            <i class="fa fa-angle-right"></i>
          </span>
        </a>
        <ul class="dropdown-menu">
          <!-- *ngIf="valuesearchbarhideshow" -->
          <div class="sidebar-search">
            <div style="position: relative; display: inline-block;">
              <input type="text" placeholder="Search..." [(ngModel)]="searchTermValue" (input)="valuefilterItems()"
                style="margin-left: 47px;" />
              <span *ngIf="searchTermValue" (click)="clearsearchvar()"
                style="position: absolute; right: 10px; top: 50%; transform: translateY(-50%); cursor: pointer; font-size: 14px; color: #aaa;">
                ✖
              </span>
            </div>
          </div>
          <ng-container *ngFor="let item of valuedashboardList">
            <li [routerLink]="item.route" routerLinkActive="active" *ngIf="permission[item.permission]"
              class="nav-item">
              <a>{{ item.label }}
                <div class="badge bg-green-lt float-right" *ngIf="item.New == true">New</div>
              </a>
            </li>
          </ng-container>
        </ul>
      </li>
      <li class="nav-item dropdown" [hidden]="!trendMainMenu">
        <a class="dropdown-toggle" (click)="clearsearchvar()">
          <span class="icon-holder">
            <i class="ti-stats-up"></i>
          </span>
          <span class="title">Trend</span>
          <span class="arrow">
            <i class="fa fa-angle-right"></i>
          </span>
        </a>
        <ul class="dropdown-menu">
          <!-- <div class="sidebar-search">
            <div style="position: relative; display: inline-block;">
              <input type="text" placeholder="Search..." [(ngModel)]="searchTermtrend" (input)="trendfilterItems()"
                style="margin-left: 47px;" />
              <span *ngIf="searchTermtrend" (click)="clearsearchvar()"
                style="position: absolute; right: 10px; top: 50%; transform: translateY(-50%); cursor: pointer; font-size: 14px; color: #aaa;">
                ✖
              </span>
            </div>
          </div> -->
          <ng-container *ngFor="let item of trenddashboardList">
            <li [routerLink]="item.route" routerLinkActive="active" *ngIf="permission[item.permission]"
              class="nav-item">
              <a>{{ item.label }}
                <div class="badge bg-green-lt float-right" *ngIf="item.New == true">New</div>
              </a>
            </li>
          </ng-container>
        </ul>
      </li>
    </ul>
  </div>
</div>