import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { CallserviceService } from 'src/app/CallServices/callservice.service';
import { SubSink } from 'subsink';

import { AuditLogService } from "../../../CallServices/audit-log.service";
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { process } from "@progress/kendo-data-query";
import { ExcelExportData } from '@progress/kendo-angular-excel-export';
import { ActivatedRoute, Router } from '@angular/router';

import { environment } from 'src/environments/environment';
import { TokenEncryptionService } from 'src/app/CallServices/token-encryption.service';
import { Utility } from "src/app/ModelFinancialMetrics/CommonService/utility";import { FilterDataService } from 'src/app/ModelFinancialMetrics/CommonService/filter-data.service';

declare var $: any;

@Component({
  selector: 'app-created-projects',
  templateUrl: './created-projects.component.html',
  styleUrls: ['./created-projects.component.css']
})
export class CreatedProjectsComponent implements OnInit {

  Selected_ProjectDetails: any;
  SerachProjectData: any[];
  SelectedProjectData: any[];
  loadingProjectGrid: boolean;
  DetailProjectData: any[];
  projectId: string;
  public selectecPracticeBadge: any;
  public Practices: any[];
  selectedPractice: any;
  ListPractices: any;
  apiclientkey: string;
  bIsLoaderOwnerUser: boolean;
  Ownerlist: any[];
  Domainuser: any[];
  OwnerListData: any[]=[];

  constructor(private Route: Router,public logapi: AuditLogService, private toaster: ToastrService, private api: CallserviceService, private datePipe: DatePipe, private fb: UntypedFormBuilder, private activatedRoute: ActivatedRoute, public _Router: Router, private filterdata: FilterDataService,
    private tokenEncryptDecrypt:TokenEncryptionService) {
    this.clsUtility = new Utility(toaster);
    this.pageSize = this.clsUtility.pagesize;
    this.MyProjectwiseDetailsExport = this.MyProjectwiseDetailsExport.bind(this);
    this.ProjectwiseDetailsExport = this.ProjectwiseDetailsExport.bind(this);
  }
  clsUtility: Utility;
  private subscription = new SubSink();
  public bClaimDetails = false;
  public LoaderLabel: any;
  public bIsLoader: any;
  public Selected_ClaimDetailsTitle: any;
  private CurrentEnvironment: any;
  private CurrentDataSet: any;
  public EditMessage: any;
  public Selected_ProjectClaimDetailsTitle: any;
  public SelectedProjectId: any;

  public loadingClaimDetailsGrid = true;
  public CreatedProjectData: any;
  public StartOfDate: Date = new Date(
    new Date().getFullYear(),
    0,
    1,
    0,
    0,
    0
  );
  public EndOfDate: Date = new Date();

  public ClaimBatchSelection: any;
  public DetailProjects_AllData: any;
  public Scorecard_MyProjects:number = 0 ;
  public Scorecard_MyProjectsOpen: any;
  public Scorecard_MyProjectsClose: any;
  public Scorecard_MyProjectsInprocess: any;
  public Scorecard_AssignedProjectCount: any;
  public Scorecard_MyProjectsComplete: any;
  public pageSize = 0;

  public ClaimCount: number = 0;
  public Balance: number = 0;
  public ProjectNamedisplay: any;
  public Descriptiontext: any;
  public SelectedExternalUserEmailtxt: any;
  public submitted = false;
  public Userlist: any;
  public RCMUserlist: any;
  public SelectedSendToUser: any;
  public SelectedSendToUserEmailtxt: any;
  public SelectedSendToUserEmail: any;
  public SelectedCCUser: any;
  public SelectedCCUserEmailtxt: any;
  public SelectedCCUserEmail: any;
  private CurrentUser: any;
  public Selected_UserClaimDetailsTitle: any;
  public min: Date = new Date(
    new DatePipe("en-US").transform(new Date(), "MM/dd/yyyy")
  );
  d = new Date();
  public EnddateValue: Date = new Date(
    this.d.getFullYear(),
    this.d.getMonth(),
    this.d.getDate() - 1,
    0,
    0,
    0
  );
  Selected_Priority: any;
  Selected_Status: any;
  ProjectDetails: any;
  public practicelist: any = [];
  public practicename: any;
  public SelectedClaims: any;
  public Selected_ProjectTitle: any;
  selectedClientkey: any;
  public Selected_Projectid = '';
  public Priority_List = [
    { text: "High", value: "High" },
    { text: "Medium", value: "Medium" },
    { text: "Low", value: "Low" },
  ];

  public Status_List = [
    { text: "Assigned", value: "Assigned" },
    { text: "In-process", value: "In-process" },
    { text: "Completed", value: "Completed" },
    { text: "Closed", value: "Closed" },
    { text: "Void", value: "Void" },
  ];

  public currentDataset: any;
  EditProjectGroup = this.fb.group({
    fcProjectName: ["", [Validators.required, Validators.maxLength(150)]],
    fcStatus: [""],
    fcPriority: [""],
    fcTo: ["", Validators.required],
    fcSendTo: [""],
    fcCC: [""],
    fcCCSendTo: [""],
    fcOther: [""],
    fcDescription: [""],
    fcExpectedCompletionDate: ["", Validators.required],
    fcProjectType: ["", Validators.required],
  });
  PracticeList: any[];
  get ProjectName() {
    return this.EditProjectGroup.get("fcProjectName");
  }
  get Status() {
    return this.EditProjectGroup.get("fcStatus");
  }
  get Priority() {
    return this.EditProjectGroup.get("fcPriority");
  }
  get AssignedTo() {
    return this.EditProjectGroup.get("fcTo");
  }
  get SendToEmail() {
    return this.EditProjectGroup.get("fcSendTo");
  }
  get CC() {
    return this.EditProjectGroup.get("fcCC");
  }
  get CCEmail() {
    return this.EditProjectGroup.get("fcCCSendTo");
  }
  get Description() {
    return this.EditProjectGroup.get("fcDescription");
  }
  get ExpectedCompletionDate() {
    return this.EditProjectGroup.get("fcExpectedCompletionDate");
  }
  get ProjectType() {
    return this.EditProjectGroup.get("fcProjectType");
  }

  ngOnInit() {
    try {
      this.bClaimDetails = false;
      this.logapi.insertActivityLog(
        "Viewed My Projects Dashboard",
        "My Projects",
        "Read"
      );
      this.selectedPractice = [];
      //this.ListPractices = JSON.parse(sessionStorage.getItem("listwithAllPractice"));
      this.ListPractices = JSON.parse(this.tokenEncryptDecrypt.decryptToken(sessionStorage.getItem("listwithAllPractice")));  //Decryption

      if(this.ListPractices==null){
        this.Route.navigate(["/qdashboards/AppAccess"])
        return
      }else {
        // this.toastr.warning("Please Assign Group");

      this.PracticeList = this.filterdata.GetUserPractices();
      let index = this.PracticeList.findIndex((x) => x.groupname == "All")
      if (index >= 0) {
        this.selectedPractice.push(this.PracticeList[index]);
      }
      //this.selectedPractice.push(this.PracticeList[0])
      this.practicename = this.PracticeList[0].groupname;




      this.activatedRoute.paramMap.subscribe(params => {
        this.projectId = params.get("id");
        //console.log(this.projectId);
      })
      this.Selected_ProjectTitle = "";
      this.Scorecard_MyProjects = 0;
      this.Scorecard_MyProjectsOpen = 0;
      this.Scorecard_MyProjectsClose = 0;
      this.Scorecard_MyProjectsInprocess = 0;
      this.Scorecard_MyProjectsComplete = 0;
      this.Scorecard_AssignedProjectCount = 0;
      this.Selected_Status = "All";
      this.Selected_Priority = "All";
      this.Selected_ProjectTitle = this.Selected_Status + " Project";
      // this.CurrentEnvironment = this.tokenEncryptDecrypt.decryptToken(sessionStorage.getItem("currentEnvironment"));
      // this.CurrentDataSet = this.tokenEncryptDecrypt.decryptToken(sessionStorage.getItem("currentDataset"))  //Decryption sessionStorage.getItem("currentDataset");
      if (environment.currentEnvironment == "Staging") {
        this.CurrentDataSet = "QinsightStagingData";
        this.CurrentEnvironment = "Staging";
      }else{
      this.CurrentDataSet = this.tokenEncryptDecrypt.decryptToken(sessionStorage.getItem("currentDataset"))  //Decryption sessionStorage.getItem("currentDataset");
      this.CurrentEnvironment = this.tokenEncryptDecrypt.decryptToken(sessionStorage.getItem("currentEnvironment")).toString(); //sessionStorage.getItem("currentEnvironment").toString();
      }
      this.CurrentUser = this.tokenEncryptDecrypt.decryptToken(sessionStorage.getItem("fname"));
      this.getProjectsData();
      this.getScoreCardData();
      //this.GetUserList(); //not in used
      this.GetOwnerandFollowerLIst(); //all triarqusers 
      this.GetRCMUserList();
      this.GetProjectType('', 'Retrive');
      this.Submit_Filter()
    }
    } catch (error) {
      this.clsUtility.LogError(error);
    }
  }

  getProjectsData() {
    try {
      this.bClaimDetails = false;
      // console.log("on  function calling");
      let userid = this.tokenEncryptDecrypt.decryptToken(sessionStorage.getItem("userid"));
      this.bIsLoader = true;
      this.loadingClaimDetailsGrid = true;
      this.CreatedProjectData = [];
      this.SerachProjectData = [];
      let querystr = "call " + this.CurrentDataSet + ".sp_specialclaimprojectdetails('" + this.apiclientkey + "','" + this.CurrentDataSet + "','" + this.datePipe.transform(this.StartOfDate, 'yyyy-MM-dd').toString() + "','" +
        this.datePipe.transform(this.EndOfDate, 'yyyy-MM-dd').toString() + "', 'All', 'All','UserWiseDetail','','" + userid + "');";
      let param: { querystring: string } = { querystring: querystr };
      this.api.getFunctionName("DynamicCallsResult ");
      const metricsdata = this.api.BindDataRCM(param).share();
      metricsdata
        .map((res) => res.json())
        .subscribe(
          (res) => {
            if (res.length > 0) {
              this.CreatedProjectData = res;
              this.SerachProjectData = res;
            }
            this.bIsLoader = false;
            this.loadingClaimDetailsGrid = false;
          },
          (err) => {
            this.bIsLoader = false;
            this.loadingClaimDetailsGrid = false;
            this.clsUtility.showWarning(err);
          }
        );
    } catch (error) {
      this.clsUtility.LogError(error);
    }
  }

  onEditNo() {
    try {
      this.EditMessage = '';
    } catch (error) {
      this.clsUtility.LogError(error);
    }
  }

  onEditYes() {
    try {
    
      this.ClaimBatchSelection = 0;
      this.ClaimBatchSelection = 1;
      let ClaimSelection: {
        actionType: any,
        Claimcount: any,
        Balance: any,
        ClaimSelection: any,
        SelectedProjectId: any,
      } = {
        actionType: "Edit",
        Claimcount: 0,
        Balance: 0,
        ClaimSelection: [],
        SelectedProjectId: this.SelectedProjectId,

      };
      sessionStorage.removeItem("ClaimBatchSelection");
      sessionStorage.setItem("ClaimBatchSelection", JSON.stringify(ClaimSelection));

      if (sessionStorage.getItem("ListofPractice")) {
        this.practicelist =  JSON.parse(this.tokenEncryptDecrypt.decryptToken(sessionStorage.getItem("ListofPractice"))); //Decryption   //JSON.parse( sessionStorage.getItem("ListofPractice") );
        this.practicelist.sort((a, b) =>
          a.groupname.localeCompare(b.groupname)
        );
        // this.selectedClientkey = this.practicelist[0].groupcode;
        // this.practicename = this.practicelist[0].groupname;
      }
      this.getProjectData();
    } catch (error) {
      this.clsUtility.LogError(error);
    }
  }

  EditProjects({ sender, rowIndex, dataItem }) {
    try {
      // console.log("dataItem : ", dataItem);

      this.SelectedProjectId = dataItem.ProjectId;
      let ProjectName = dataItem.ProjectName;
      this.EditMessage = "Do you want to edit project " + ProjectName + "?";
    } catch (error) {
      this.clsUtility.LogError(error);
    }
  }

  OutputCreateProjectResult() {
    try {
      this.ClaimBatchSelection = 0;
    } catch (error) {
      this.clsUtility.LogError(error);
    }
  }

  getScoreCardData() {
    try {
      let userid = this.tokenEncryptDecrypt.decryptToken(sessionStorage.getItem("userid"));
      this.bIsLoader = true;
      this.Scorecard_MyProjects = 0;
      this.Scorecard_MyProjectsOpen = 0;
      this.Scorecard_MyProjectsClose = 0;
      this.Scorecard_MyProjectsInprocess = 0;
      this.Scorecard_MyProjectsComplete = 0;
      this.Scorecard_AssignedProjectCount = 0;
      let querystr = "call " + this.CurrentDataSet + ".sp_specialclaimprojectdetails('" + this.apiclientkey + "','" + this.CurrentDataSet + "','" + this.datePipe.transform(this.StartOfDate, 'yyyy-MM-dd').toString() + "','" +
        this.datePipe.transform(this.EndOfDate, 'yyyy-MM-dd').toString() + "', 'All',  'All','ProjectSummary','','" + userid + "');";
      let param: { querystring: string } = { querystring: querystr };
      this.api.getFunctionName("DynamicCallsResult ");
      const metricsdata = this.api.BindDataRCM(param).share();
      metricsdata
        .map((res) => res.json())
        .subscribe(
          (res) => {
            if (res.length > 0) {
              this.Scorecard_MyProjects = res[0]['TotalProjectCount'];
              this.Scorecard_MyProjectsOpen = res[0]['OpenProjectCount'];
              this.Scorecard_MyProjectsClose = res[0]['CloseProjectCount'];
              this.Scorecard_MyProjectsInprocess = res[0]['InprocessProjectCount'];
              this.Scorecard_AssignedProjectCount = res[0]['AssignedProjectCount'];
              this.Scorecard_MyProjectsComplete = res[0]['CompletedProjectCount']
            }
            this.bIsLoader = false;
          },
          (err) => {
            this.bIsLoader = false;
            this.clsUtility.showWarning(err);
          }
        );
    } catch (error) {
      this.clsUtility.LogError(error);
    }
  }
  PracticeSelect(val: any) {
    this.selectedClientkey = this.filterdata.GetUserPractices();
  }
  Submit_Filter() {
    try {
      this.practicename = ""
      for (let i = 0; i < this.selectedPractice.length; i++) {
        if (this.practicename == "") {
          this.practicename = this.selectedPractice[i].groupname;
        } else {
          this.practicename = this.practicename + "," + this.selectedPractice[i].groupname;
        }
      }
      this.Practices = []
      this.selectecPracticeBadge != "";
      this.selectecPracticeBadge = this.Practices;
      for (let i = 0; i < this.selectedPractice.length; i++) {
        if (this.selectedPractice[i].isparent) {
          for (let j = 0; j < this.ListPractices.length; j++) {
            if (
              this.ListPractices[j].parentgroupid ==
              this.selectedPractice[i].groupid
            ) {
              this.Practices.push(this.ListPractices[j].groupcode);
            }
          }
        }

        if (this.selectedPractice[i].groupid == 0) {
          if (!this.selectedPractice[i].isparent) {
            for (let j = 0; j < this.PracticeList.length; j++) {
              if (
                !this.PracticeList[j].isparent &&
                this.PracticeList[j].groupid != 0
              ) {
                this.Practices.push(this.PracticeList[j].groupcode);
                //practicename.push(this.PracticeList[j].groupname);
              }
            }
          }
        }

        if (!this.selectedPractice[i].isparent) {
          // DisplayPractices.push(this.selectedPractice[i]);
          let index = this.Practices.findIndex(
            (x) => x == this.selectedPractice[i].groupcode
          );
          if (index < 0 && this.selectedPractice[i].groupid != 0) {
            this.Practices.push(this.selectedPractice[i].groupcode);
            //practicename.push(this.selectedPractice[i].groupname);
          }
        } else {
          //DisplayPractices.push(this.selectedPractice[i]);
        }
      }

      this.apiclientkey = '';
      let SelectClientKey = null;

      if (this.Practices.length > 0) {
        this.Practices.forEach((obj) => {
          if (SelectClientKey == null) {

            SelectClientKey = '[' + '"' + obj + '"';
          }
          else {
            SelectClientKey = SelectClientKey + ',"' + obj + '"';
          }
        })
        this.apiclientkey = SelectClientKey + ']';
      }
      this.Selected_ProjectTitle = this.Selected_Status + " Project";

      this.logapi.insertActivityLog(
        "Viewed My Project dashboard" + " " + "For" + " " + this.practicename,
        "My Project ",
        "Read"
      );

      this.getScoreCardData();
      this.getProjectsData();
    } catch (error) {
      this.clsUtility.LogError(error);
    }
  }

  Close_Filter() {
    try {

    } catch (error) {
      this.clsUtility.LogError(error);
    }
  }

  Follower: any;
  onEdit() {
    try {
      this.bIsLoader = true;
      this.LoaderLabel = "Updating Details";
      const datepipe = new DatePipe("en-US");
      const currentDate = datepipe.transform(
        Date.now(),
        "MM-dd-yyyy"
      );
      let CurrentUser: any = JSON.parse(this.tokenEncryptDecrypt.decryptToken(localStorage.getItem("currentUser")));
      let userid = this.tokenEncryptDecrypt.decryptToken(sessionStorage.getItem("userid"));
      let userEmail =  this.tokenEncryptDecrypt.decryptToken(sessionStorage.getItem("username"))  //Decryption  //sessionStorage.getItem("username");
      let token =  this.tokenEncryptDecrypt.decryptToken(sessionStorage.getItem("usertoken"))  //Decryption//sessionStorage.getItem("usertoken");
      let ClientIP = CurrentUser.visitorip;
      let ClientBrowser = CurrentUser.visitorbrowser;
      let UserName = CurrentUser.userFullName;
      let AssignedSendToName: any = [];
      AssignedSendToName.push(this.AssignedTo.value.displayname);

      // let AssignedCCName: any = [];
      // for (let i = 0; i < this.CC.value.length; i++) {
      //   if (!isNullOrUndefined(AssignedCCName)) {
      //     AssignedCCName.push(this.CC.value[i].displayname);
      //   }
      // }

      this.Follower = [];
      let AssignedCCName: any = [];
      for (let i = 0; i < this.CC.value.length; i++) {
        let follower: {
          AssignedCCId: any;
          AssignedCCName: any;
          AssignedCCEmail: any;
        } = {
          AssignedCCId: '',
          AssignedCCName: '',
          AssignedCCEmail: '',
        }
        if (!isNullOrUndefined(AssignedCCName)) {
          follower.AssignedCCId = this.CC.value[i].userid;
          follower.AssignedCCName = this.CC.value[i].displayname;
          follower.AssignedCCEmail = this.CC.value[i].username;
          this.Follower.push(follower);
          AssignedCCName.push(this.CC.value[i].displayname);
        }
      }

      // let input_claimbatch: [];

      let input_claimbatch: {
        ProjectId: any;
        ProjectName: any;
        ClaimData: any;
        Projecttype: any;
        DatasetName: any;
        PracticeName: any;
        Status: any;
        CreatedOn: any;
        CreatedBy: any;
        CreatedByUserid: any;
        ExpectedCompletionDate: any;
        Priority: any;
        Description: any;
        AssigneeName: any;
        AssigneeEmail: any;
        AssignedToName: any;
        AssignedToEmail: any;
        AssignedOn: any;
        AssignedCCName: any;
        AssignedCCEmail: any;
        ExternalUserEmail: any;
        ClaimCount: any;
        Balance: any;
        AssignedToUserId: any;
        envname: any;
        DML: any;
        AssignedCC: any;
      } = {
        ProjectId: this.SelectedProjectId,
        ProjectName: this.ProjectNamedisplay,
        ClaimData: JSON.parse(this.ProjectDetails[0]["ClaimData"]),
        Projecttype: !isNullOrUndefined(this.Selected_ProjectType) ? this.Selected_ProjectType['Projecttype'] : '',//this.ProjectDetails[0]["Projecttype"],
        DatasetName: this.ProjectDetails[0]["DatasetName"],
        PracticeName: this.ProjectDetails[0]["PracticeName"],
        Status: !isNullOrUndefined(this.Status.value) ? this.Status.value : '',
        CreatedOn: this.datePipe.transform(Date.now(), 'yyyy-MM-ddTHH:mm:ss.SSSZ').toString(),
        CreatedBy: this.CurrentUser,
        CreatedByUserid: userid,
        ExpectedCompletionDate: this.datePipe.transform(this.EnddateValue, 'yyyy-MM-dd').toString(),
        Priority: !isNullOrUndefined(this.Selected_Priority) ? this.Selected_Priority : '',
        Description: this.Descriptiontext,
        AssigneeName: this.CurrentUser,
        AssigneeEmail: userEmail,
        AssignedToName: AssignedSendToName.toString(),
        AssignedToEmail: this.SendToEmail.value,
        AssignedCCName: AssignedCCName.toString(),
        AssignedCCEmail: this.CCEmail.value,
        ExternalUserEmail: !isNullOrUndefined(this.SelectedExternalUserEmailtxt) ? this.SelectedExternalUserEmailtxt : '',
        ClaimCount: this.ClaimCount.toString(),
        Balance: this.Balance.toString(),
        AssignedToUserId: this.AssignedTo.value.userid,
        AssignedOn: this.datePipe.transform(Date.now(), 'yyyy-MM-ddTHH:mm:ss.SSSZ').toString(),
        envname: this.CurrentEnvironment,
        DML: "Update",
        AssignedCC: this.Follower == [] ? "[]" : this.Follower,
      };
      input_claimbatch['Projecttype'] = isNullOrUndefined(input_claimbatch['Projecttype']) ? '' : input_claimbatch['Projecttype'];
      // let querystr = "call " + this.CurrentDataSet + ".SP_insertworkqueuemasterdetails('" + this.CurrentDataSet + "','" + JSON.stringify(input_claimbatch) + "','savedata')";
      let querystr = "call " + this.CurrentDataSet + ".SP_insertworkqueuemasterdetails('" + this.CurrentDataSet + "','" + JSON.stringify(input_claimbatch) + "','" + input_claimbatch['ProjectId'] + "','" + input_claimbatch['ProjectName'] + "','" + JSON.stringify(input_claimbatch['ClaimData']) + "','" + input_claimbatch['Projecttype'] + "','" + input_claimbatch['DatasetName'] + "','" + input_claimbatch['PracticeName'] + "','" + input_claimbatch['Status'] + "','" + input_claimbatch['CreatedOn'] + "','" + input_claimbatch['CreatedBy'] + "','" + input_claimbatch['CreatedByUserid'] + "','" + input_claimbatch['ExpectedCompletionDate'] + "','" + input_claimbatch['Priority'] + "','" + input_claimbatch['Description'] + "','" + input_claimbatch['AssigneeName'] + "','" + input_claimbatch['AssigneeEmail'] + "','" + input_claimbatch['AssignedToName'] + "','" + input_claimbatch['AssignedToEmail'] + "','" + input_claimbatch['AssignedOn'] + "','" + input_claimbatch['AssignedCCName'] + "','" + input_claimbatch['AssignedCCEmail'] + "','" + input_claimbatch['ExternalUserEmail'] + "','" + input_claimbatch['ClaimCount'] + "','" + input_claimbatch['Balance'] + "','" + input_claimbatch['AssignedToUserId'] + "','" + input_claimbatch['DML'] + "','" + JSON.stringify(input_claimbatch['AssignedCC']) + "','savedata')";
      let param:
        {
          querystring: string,
          loginuser: any,
          clientip: any,
          clientbrowser: any,
          authorization: any,
          follower: any,
          type: any,
          clientkey: any,
          projectdetails: any,
        } = {
        querystring: querystr,
        loginuser: UserName,
        clientip: ClientIP,
        clientbrowser: ClientBrowser,
        authorization: "Bearer " + token,
        follower: this.Follower,
        type: 'Update',
        clientkey: this.CurrentDataSet,
        projectdetails: input_claimbatch
      };
      this.api.getFunctionName("SpecialClaimDynamicCallsResult ");
      const metricsdata = this.api.BindDataRCM(param).share();
      metricsdata
        .map((res) => res.json())
        .subscribe(
          (res) => {
            if (!isNullOrUndefined(res) && res != [] && res.length > 0) {
              if (res[0].hasOwnProperty("ProjectId") == true && res[0]["ProjectId"] != '' && res[0]["ProjectId"].length > 0) {
                this.clsUtility.showSuccess('Record updated successfully');
                this.Save_SendEmail(res[0]["ProjectId"]);
                this.logapi.insertActivityLog(
                  this.ProjectNamedisplay + " project updated successfully",
                  "My Projects",
                  "Update"
                );
                this.bIsLoader = false;
                document.getElementById('hidemodal').click();
                this.getProjectsData();
              }
              else if (res[0].hasOwnProperty("Message") == true && res[0]["Message"] != '' && res[0]["Message"].length > 0) {
                this.clsUtility.showWarning(res[0]["Message"]);
                this.bIsLoader = false;
                // this.clsUtility.showError("Error while updating project");
              }
              else {
                this.clsUtility.showError("Error while updating project");
                this.bIsLoader = false;
              }
            } else {
              this.bIsLoader = false;
              this.clsUtility.showError("Error while updating project");
            }

          },
          (err) => {
            this.bIsLoader = false;
            this.clsUtility.showWarning(err);
            this.logapi.insertActivityLog(
              this.ProjectNamedisplay + " error while updating project",
              "My Project",
              "Read"
            );
          }
        );

    } catch (error) {
      this.clsUtility.LogError(error);
    }
  }


  public ProjectwiseDetailsExport(): ExcelExportData {

    const result: ExcelExportData = {
      data: process(this.Selected_ProjectDetails, {
        sort: [{ field: "chargesclosedate", dir: "asc" }],
      }).data,
    };
    return result;
  }

  public MyProjectwiseDetailsExport(): ExcelExportData {
    this.logapi.insertActivityLog(
      "Downloaded My Project Summary",
      "My Project",
      "Export"
    );
    const result: ExcelExportData = {
      data: process(this.CreatedProjectData, {
        sort: [{ field: "chargesclosedate", dir: "asc" }],
      }).data,
    };
    return result;
  }
  onCancel() {
    try {

    } catch (error) {
      this.clsUtility.LogError(error);
    }
  }

  onCloseModal() {
    try {
      this.getScoreCardData();
      this.getProjectsData();
    } catch (error) {
      this.clsUtility.LogError(error);
    }
  }

  SelectSendToUserEmailSingle(user: any) {
    try {
      this.SelectedSendToUserEmail = [];
      this.SelectedSendToUserEmailtxt = "";
      if (user != null && user != undefined) {
        this.SelectedSendToUserEmailtxt =
          this.SelectedSendToUserEmailtxt + user["email"];
      }
    } catch (error) {
      this.clsUtility.LogError(error);
    }
  }

  SelectCCUserEmail(user: any) {
    try {
      this.SelectedCCUserEmail = [];
      this.SelectedCCUserEmailtxt = "";
      if (user != null && user != undefined) {
        for (let i = 0; i < user.length; i++) {
          if (i == user.length - 1) {
            this.SelectedCCUserEmailtxt =
              this.SelectedCCUserEmailtxt + user[i]["email"];
          } else {
            this.SelectedCCUserEmailtxt =
              this.SelectedCCUserEmailtxt + user[i]["email"] + ";";
          }
        }
      }
    } catch (error) {
      this.clsUtility.LogError(error);
    }
  }

  GetUserList() {
    try {
      this.SelectedSendToUser = [];
      this.SelectedSendToUserEmailtxt = "";
      this.SelectedCCUser = [];
      this.SelectedCCUserEmailtxt = "";
      this.Userlist = [];

      const seq = this.api.getUser().share();
      seq
        .map((res) => res.json())
        .subscribe((res) => {
          this.Userlist = res;
        });
    } catch (error) {
      this.clsUtility.LogError(error);
    }
  }



  //New Owner and Follower List (TFL Dashboard)
  GetOwnerandFollowerLIst() {
    try {
     
      this.bIsLoaderOwnerUser = true;
      this.SelectedSendToUser = [];
      this.SelectedSendToUserEmailtxt = "";
      this.Ownerlist = [];
      const seq = this.api.getUser().share();
      seq
        .map((res) => res.json())
        .subscribe((res) => {
          this.Ownerlist = res;
            //debugger;
            const filteredData = this.Ownerlist.filter(obj => obj.email.endsWith('@triarqhealth.com'));
             this.Domainuser=filteredData;
             this.OwnerListData = this.Domainuser.filter((value, index, self) => {
              return self.findIndex(s => s.userid === value.userid) === index;
            });
          
             this.bIsLoaderOwnerUser = false;

        });
    } catch (error) {
      this.bIsLoaderOwnerUser = false;
      this.clsUtility.LogError(error);
    }
  }


  getProjectData() {
    try {
   
      this.bClaimDetails = true;
      this.bIsLoader = true;
      this.Selected_Status = '';
      this.Selected_Priority = '';
      this.ClaimCount = 0;
      this.Balance = 0;

      let querystr =
        "call " +
        this.CurrentDataSet +
        ".sp_specialclaimprojectdetails('','" +
        this.CurrentDataSet +
        "','','','','','ProjectWiseFilter','" +
        this.SelectedProjectId +
        "','')";

      let param: { querystring: string } = { querystring: querystr };
      this.api.getFunctionName("DynamicCallsResult ");
      const metricsdata = this.api.BindDataRCM(param).share();
      metricsdata
        .map((res) => res.json())
        .subscribe(
          (res) => {
            if (res.length > 0) {
              this.ProjectDetails = res;
              this.bIsLoader = false;
              this.ClaimCount = res[0].ClaimCount;
              this.Balance = res[0].Balance;
              this.ProjectNamedisplay = res[0].ProjectName;
              this.EnddateValue = new Date(res[0].ExpectedCompletionDate);
              this.selectedClientkey = (res[0].DatasetName);
              // status
              let gindex = this.Status_List.findIndex(
                (x) => x.text == res[0].Status
              );
              if (gindex >= 0) {
                this.Selected_Status = this.Status_List[gindex].text;
              }
              //Priority
              let pindex = this.Priority_List.findIndex(
                (x) => x.text == res[0].Priority
              );
              if (pindex >= 0) {
                this.Selected_Priority = this.Priority_List[pindex].text;
              }
              //Owner
              let oindex = this.OwnerListData.findIndex(
                (x) => x.displayname == res[0].AssignedToName
              );
              if (oindex >= 0) {
                this.SelectedSendToUser = this.OwnerListData[oindex];
                this.SelectedSendToUserEmailtxt = this.OwnerListData[oindex].email;
              }

              //ProjectType
              let projectindex = this.ProjectTypeList.findIndex((x) => x.Projecttype == res[0].Projecttype);
              if (projectindex >= 0) {
                this.Selected_ProjectType = this.ProjectTypeList[projectindex];
              }

              //follower
              this.SelectedCCUser = [];
              this.SelectedCCUserEmailtxt = "";
              let ccemails = res[0].AssignedCCEmail.split(";");
              for (let i = 0; i < ccemails.length; i++) {
                let findex = this.OwnerListData.findIndex(
                  (x) => x.email == ccemails[i]
                );
                if (findex >= 0) {
                  this.SelectedCCUser.push(this.OwnerListData[findex]);
                  this.SelectedCCUserEmailtxt =
                    this.SelectedCCUserEmailtxt +
                    this.OwnerListData[findex].email +
                    ",";
                }
              }
              this.Descriptiontext = res[0].Description;
            }
            this.bIsLoader = false;
          },
          (err) => {
            this.bIsLoader = false;
            this.clsUtility.showWarning(err);
          }
        );
    } catch (error) {
      this.bIsLoader = false;
      this.clsUtility.LogError(error);
    }
  }

  OnCellClickCreatedProject($event) {
    try {
      let clickedevent = $event;
      if (clickedevent.column.field == "ProjectName") {
        this.Selected_Projectid = '';
        if (!isNullOrUndefined(clickedevent)) {
          this.Selected_Projectid = clickedevent.dataItem['ProjectId'];
          this._Router.navigate(["/qdashboards/Projects/" + this.Selected_Projectid]);
          this.Selected_ClaimDetailsTitle = "Claim Details of " + clickedevent.dataItem['ProjectName'];
          this.Selected_ProjectClaimDetailsTitle = "Project claim status of " + clickedevent.dataItem['ProjectName'];
          this.Selected_UserClaimDetailsTitle = "Last worked user wise claim status of " + clickedevent.dataItem['ProjectName'];
          //call comment because redirected to projects dashboard
          // this.getDetailProjectData();
          // Uncommnet this code when call is ready
          // this.ProjectWiseClaimDetails();
          /* this.getProjectClaimStatusBucketData(); */
          /* this.getUserWiseClaimStatusBucketData(); */
        }
      }
    } catch (error) {
      this.clsUtility.LogError(error);
    }
  }

  getDetailProjectData() {
    try {
      this.bClaimDetails = true;
      this.loadingProjectGrid = true;
      this.DetailProjects_AllData = [];
      this.DetailProjectData = [];
      let querystr = "call " + this.CurrentDataSet + ".sp_specialclaimprojectdetails('" + this.apiclientkey + "','" + this.CurrentDataSet + "','" + this.datePipe.transform(this.StartOfDate, 'yyyy-MM-dd').toString() + "','" +
        this.datePipe.transform(this.EndOfDate, 'yyyy-MM-dd').toString() + "', '" + this.Selected_Priority + "', '" + this.Selected_Status + "','claimdetail','" + this.Selected_Projectid + "','');";
      let param: { querystring: string } = { querystring: querystr };
      this.api.getFunctionName("DynamicCallsResult ");
      const metricsdata = this.api.BindDataRCM(param).share();
      metricsdata
        .map((res) => res.json())
        .subscribe(
          (res) => {
            if (res.length > 0) {
              this.DetailProjects_AllData = res;
              this.DetailProjectData = res;
              this.loadingProjectGrid = false;

            }
          },
          (err) => {
            this.clsUtility.showWarning(err);
            this.loadingProjectGrid = false;
          }
        );
    } catch (error) {
      this.clsUtility.LogError(error);
    }
  }
  public DetailProjectSearch(inputValue: string): void {
    this.CreatedProjectData = process(this.SerachProjectData, {
      filter: {
        logic: "or",
        filters: [
          {
            field: "AssignedToName",
            operator: "contains",
            value: inputValue
          },
          {
            field: "Balance",
            operator: "contains",
            value: inputValue
          },
          {
            field: "ClaimCount",
            operator: "contains",
            value: inputValue
          },
          {
            field: "CreatedBy",
            operator: "contains",
            value: inputValue
          },
          {
            field: "Description",
            operator: "contains",
            value: inputValue
          },
          {
            field: "ProjectName",
            operator: "contains",
            value: inputValue
          },


        ],
      }
    }).data;
  }

  public ProjectSearch(inputValue: string): void {
    this.Selected_ProjectDetails = process(this.SelectedProjectData, {
      filter: {
        logic: "or",
        filters: [
          {
            field: "BillingProvidersFullName",
            operator: "contains",
            value: inputValue
          },
          {
            field: "ChargeAmount",
            operator: "contains",
            value: inputValue
          },
          {
            field: "ClaimNo",
            operator: "contains",
            value: inputValue
          },
          {
            field: "FacilityName",
            operator: "contains",
            value: inputValue
          },
          {
            field: "InsuranceDue",
            operator: "contains",
            value: inputValue
          },
          {
            field: "PatientDue",
            operator: "contains",
            value: inputValue
          },
          {
            field: "TotalDue",
            operator: "contains",
            value: inputValue
          },


        ],
      }
    }).data;
  }


  ProjectWiseClaimDetails() {
    try {
      this.bClaimDetails = true;
      this.loadingProjectGrid = true;
      this.Selected_ProjectDetails = [];
      this.SelectedProjectData = [];
      let querystr = "call " + this.CurrentDataSet + ".sp_specialclaimprojectdetails('" + this.apiclientkey + "','" + this.CurrentDataSet + "','" + this.datePipe.transform(this.StartOfDate, 'yyyy-MM-dd').toString() + "','" +
        this.datePipe.transform(this.EndOfDate, 'yyyy-MM-dd').toString() + "','" + this.Selected_Priority + "', '" + this.Selected_Status + "','ClaimDetailsProjectWise','" + this.Selected_Projectid + "','');";
      let param: { querystring: string } = { querystring: querystr };
      this.api.getFunctionName("DynamicCallsResult ");
      const metricsdata = this.api.BindDataRCM(param).share();
      metricsdata
        .map((res) => res.json())
        .subscribe(
          (res) => {
            if (res.length > 0) {
              this.DetailProjectData = res;
              this.Selected_ProjectDetails = res;
              this.SelectedProjectData = res;
            }
            this.loadingProjectGrid = false;
          },
          (err) => {
            this.Selected_ProjectDetails = [];
            this.clsUtility.showWarning(err);
            this.loadingProjectGrid = false;
          }
        );
    } catch (error) {
      this.loadingProjectGrid = false;
      this.Selected_ProjectDetails = [];
      this.clsUtility.LogError(error);
    }
  }
  onCloseModelClick() {
    try {

    } catch (error) {
      this.clsUtility.LogError(error);
    }
  }

  GetRCMUserList() {
    try {
      this.SelectedSendToUser = [];
      this.SelectedSendToUserEmailtxt = "";
      this.RCMUserlist = [];

      const seq = this.api.getRCMUserByRoleId().share();
      seq
        .map((res) => res.json())
        .subscribe((res) => {
          let rcm = res;
          let rcmusers: any;

          if (!isNullOrUndefined(rcm) && rcm.length > 0) {
            let r: {
              id: any,
              userid: any;
              firstname: any;
              lastname: any;
              mobilenumber: any;
              email: any;
              homephone: any;
              usergroups: any;
              displayname: any;
            } = {
              id: '',
              userid: '',
              firstname: '',
              lastname: '',
              mobilenumber: '',
              email: '',
              homephone: '',
              usergroups: [],
              displayname: '',
            };
            for (let i = 0; i < rcm.length; i++) {
              r.id = rcm[i].id;
              r.userid = rcm[i].userid;
              r.firstname = rcm[i].firstname;
              r.lastname = rcm[i].lastname;
              r.mobilenumber = rcm[i].mobilenumber;
              r.email = rcm[i].email;
              r.homephone = rcm[i].homephone;
              r.usergroups = rcm[i].usergroups;
              r.displayname = rcm[i].firstname + " " + rcm[i].lastname;
              this.RCMUserlist.push({ ...r });
            }

          }
        });
    } catch (error) {
      this.clsUtility.LogError(error);
    }
  }

  Save_SendEmail(ProjectId: any) {
    try {
      const datepipe = new DatePipe("en-US");
      const currentDate = datepipe.transform(Date.now(), "MM-dd-yyyy");

      // let ClaimSelection: any = sessionStorage.getItem("ClaimBatchSelection");
      // let Claim = JSON.parse(ClaimSelection);

      let AssignedSendToName: any = [];
      AssignedSendToName.push(this.AssignedTo.value.displayname);

      let AssignedCCName: any = [];
      for (let i = 0; i < this.CC.value.length; i++) {
        if (!isNullOrUndefined(AssignedCCName)) {
          AssignedCCName.push(this.CC.value[i].displayname);
        }
      }

      let input_claimbatch: {
        envname: any;
        DatasetName: any;
        PracticeName: any;
        ProjectId: any;
        ProjectName: any;
        Status: any;
        Priority: any;
        AssignedToName: any;
        AssignedToEmail: any;
        // AssignedCCName: any;
        // AssignedCCEmail: any;
        Description: any;
        ExpectedCompletionDate: any;
        ExternalUserEmail: any;
        ClaimCount: any;
        Balance: any;
        CreatedOn: any;
        CreatedBy: any;
        AssigneeName: any;
        AssignedOn: any;
        AssignedToUserId: any;
        DML: any;
        Projecttype: any;
      } = {
        envname: this.CurrentEnvironment,
        DatasetName: this.selectedClientkey,
        ProjectId: ProjectId,
        PracticeName: this.ProjectDetails[0]["PracticeName"],
        ProjectName: this.ProjectName.value,
        Status: this.Status.value,
        Priority: this.Priority.value,
        AssignedToName: AssignedSendToName.toString(),
        AssignedToEmail: !isNullOrUndefined(this.SendToEmail.value) ? this.SendToEmail.value : '',
        Description: !isNullOrUndefined(this.Description.value) ? this.Description.value : '',
        ExpectedCompletionDate: this.datePipe
          .transform(this.EnddateValue, "yyyy-MM-dd")
          .toString(),
        ExternalUserEmail: !isNullOrUndefined(this.SelectedExternalUserEmailtxt)
          ? this.SelectedExternalUserEmailtxt
          : "",
        ClaimCount: this.ClaimCount.toString(),
        Balance: Math.round(this.Balance).toString(),
        CreatedOn: this.datePipe
          .transform(currentDate, "yyyy-MM-ddTHH:mm:ss.SSSZ")
          .toString(),
        CreatedBy: this.CurrentUser,
        AssigneeName: this.CurrentUser,
        AssignedOn: this.datePipe
          .transform(currentDate, "yyyy-MM-ddTHH:mm:ss.SSSZ")
          .toString(),
        AssignedToUserId: this.AssignedTo.value.userid,
        DML: "Insert",
        Projecttype: !isNullOrUndefined(this.Selected_ProjectType) ? this.Selected_ProjectType['Projecttype'] : '',
      };
      input_claimbatch['Projecttype'] = isNullOrUndefined(input_claimbatch['Projecttype']) ? '' : input_claimbatch['Projecttype'];
      let input_data: {
        data: any;
      } = {
        data: input_claimbatch,
      };

      this.api.getFunctionName("workqueuemail");
      let seq = this.api.BindDataRCM(input_data).share();
      this.subscription.add(
        seq.subscribe(
          (response) => {
            if (response != null && response != undefined) {
              let result: any = response;
              if (result != []) {
                let res: any = result._body;
                if (res == "Email Send and Data Insert Successfully Done") {
                  // this.clsUtility.showSuccess("Data saved successfully");
                } else if (res == "Unsuccessfull Email Send") {
                  this.clsUtility.showError("Email not sent");
                }
              } else {
                this.clsUtility.showError("Email not sent");
              }
            } else {
              this.clsUtility.showError("Email not sent");
            }
          },
          (err) => {
            this.clsUtility.LogError(err);
          }
        )
      );
    } catch (error) {
      this.clsUtility.LogError(error);
    }
  }

  public Selected_ProjectType: any;
  public filter: string;
  public ProjectTypeList: Array<{ Projecttype: string; Projecttypeid: number }> = [
    { Projecttype: "Small", Projecttypeid: 1 },
    { Projecttype: "Medium", Projecttypeid: 2 },
    { Projecttype: "Large", Projecttypeid: 3 },
  ];

  public data: Array<{ Projecttype: string; Projecttypeid: number }>;
  public addNew(): void {
    this.ProjectTypeList.push({
      Projecttype: this.filter,
      Projecttypeid: 0,
    });
    this.handleFilter(this.filter);
  }

  public handleFilter(value) {
    this.filter = value;
    this.data = this.ProjectTypeList.filter(
      (s) => s.Projecttype.toLowerCase().indexOf(value.toLowerCase()) !== -1
    );
  }

  GetProjectType(ProjectType: string, operationtype: string) {
    try {
      this.bIsLoader = true;
      let input_claimbatch: {
        commondatabasename: any;
        type: any;
        Projecttype: any;
      } = {
        commondatabasename: this.CurrentDataSet,
        type: operationtype,
        Projecttype: ProjectType,
      };

      let querystr = "call " + this.CurrentDataSet + ".SP_insertprojecttype('" + JSON.stringify(input_claimbatch) + "')";
      let param: { querystring: string } = { querystring: querystr };
      this.api.getFunctionName("DynamicCallsResult ");
      const metricsdata = this.api.BindDataRCM(param).share();
      metricsdata
        .map((res) => res.json())
        .subscribe(
          (res) => {
            if (!isNullOrUndefined(res)) {
              if (res.length > 0 && res[0]['f0_'] != '[]' && res[0]['f0_'] == 'New Project Type Added') {
                this.logapi.insertActivityLog(
                  "Projecttype " + ProjectType + " added successfully",
                  "My Projects",
                  "Read"
                );
                this.data = [];
                this.data = res;
                this.ProjectTypeList = [];
                this.ProjectTypeList = res;
                if (!isNullOrUndefined(this.ProjectTypeList)) {
                  this.data = this.ProjectTypeList.slice(0);
                }
                this.bIsLoader = false;
              }
              else if (res.length > 0 && res[0]['f0_'] != '[]' && res[0]['f0_'] == 'Project Type Already Exists') {
                this.clsUtility.showInfo("Project type already exists");
                this.bIsLoader = false;
              }
              else if (res.length > 0 && res != '[]') {
                this.data = [];
                this.data = res;
                this.ProjectTypeList = [];
                this.ProjectTypeList = res;
                if (!isNullOrUndefined(this.ProjectTypeList)) {
                  this.data = this.ProjectTypeList.slice(0);
                }
                this.bIsLoader = false;
              }
              else if (res != '[]') {
                this.data = [];
                this.data = res;
                this.ProjectTypeList = [];
                this.ProjectTypeList = res;
                if (!isNullOrUndefined(this.ProjectTypeList)) {
                  this.data = this.ProjectTypeList.slice(0);
                }
                this.bIsLoader = false;
              }
            }
          },
          (err) => {
            this.bIsLoader = false;
            this.clsUtility.showWarning(err);
            this.logapi.insertActivityLog(
              this.ProjectNamedisplay + " error while inserting projecttype",
              "My Projects",
              "Read"
            );
          }
        );
    } catch (error) {
      this.clsUtility.LogError(error);
    }
  }
}
function isNullOrUndefined(value: any): boolean { return value === null || value === undefined; }
