import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'searchFilter'
})
export class SearchFilterPipe implements PipeTransform {

  transform(value: any, args?: any): any {
    if (!value) return null;
    if (!args) return value;

    args = args.trim().replace(/\s+/g, ' ');
    args = args.toLowerCase();

    // return value.filter(function (data) {                               //previous old implementation comparing all data with key and value
    //   return JSON.stringify(data).toLowerCase().includes(args);
    // });

    return value.filter(function (data) {                                 //new implementation comparing only values not key
      // Check only the values in the object
      return Object.values(data).some(val =>
        String(val).toLowerCase().includes(args)
      );
    });


    // below implementation is for POC do not touch it

    // if (mode == 'default') {
    //   return value.filter(function (data) {                                 //new implementation comparing only values not key
    //     // Check only the values in the object
    //     return Object.values(data).some(val =>
    //       String(val).toLowerCase().includes(args)
    //     );
    //   });
    // } else {
    //   return value.filter(function (data) {                               //previous old implementation comparing all data with key and value
    //     return JSON.stringify(data).toLowerCase().includes(args);
    //   });
    // }
  }
}
